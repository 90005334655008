@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Mulish:ital,wght@0,200..1000;1,200..1000&display=swap");

* {
  font-family: "Montserrat", monospace;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.swiper-pagination {
  bottom: -2rem !important;
  background-color: red;
}

/* Styles for active bullet */
.swiper-pagination-bullet-active {
  width: 40px;
  height: 16px;
  border-radius: 1000px;
  background-color: #cbf356;
}

/* Styles for inactive bullet */
.swiper-pagination-bullet {
  width: 16px;
  height: 16px;
  border-radius: 1000px;
  background-color: #343231;
}
